<template>
  <div class="flex flex-1 flex-col min-h-0 overflow-auto hierarchy-explorer">
    <RecycleScroller
      ref="virtualListRef"
      class="flex-1"
      :items="nodes"
      :key-field="keyField"
      :buffer="100"
      :item-size="rowHeight"
      @visible="selectInitialValue"
    >
      <template v-slot="{ index, item }">
        <div
          :style="{ height: `${rowHeight}px`, padding: '0.175rem 0' }"
          class="flex flex-1 min-w-0"
        >
          <TreeNodeWrapper
            :node="item"
            :tree="tree"
            :state="item.state"
            :with-bg="withBg"
            :highlight-term="searchTerm"
            :selected-nodes-id="
              value ? (Array.isArray(value) ? value : [value.id]) : []
            "
            :only-leaf-node-selectable="onlyLeafNodeSelectable"
            @click="() => handleChange(item, tree)"
          >
            <slot
              :item="item"
              :tree="tree"
              :can-add="item.state.depth + 1 < maxLevel"
            >
              {{ item.name }}
            </slot>
          </TreeNodeWrapper>
        </div>
      </template>
    </RecycleScroller>
  </div>
</template>

<script>
import Debounce from 'lodash/debounce'
import IsEqual from 'lodash/isEqual'
import UniqBy from 'lodash/uniqBy'
import InfiniteTree from 'infinite-tree'
import TreeNodeWrapper from './tree-node-wrapper.vue'

export default {
  name: 'InfiniteTree',
  components: {
    TreeNodeWrapper,
  },
  model: { event: 'change' },
  props: {
    keyField: {
      type: String,
      default: 'id',
    },
    openIds: {
      type: Array,
      default() {
        return []
      },
    },
    data: {
      type: [Array],
      default() {
        return []
      },
    },
    rowHeight: {
      type: Number,
      default: 46,
    },
    value: {
      type: [Object, Array],
      default: undefined,
    },
    searchTerm: {
      type: String,
      default: undefined,
    },
    filterFn: {
      type: Function,
      default: undefined,
    },
    nodeFields: {
      type: Array,
      default() {
        return ['name']
      },
    },
    maxLevel: {
      type: Number,
      default() {
        return 5
      },
    },
    withBg: {
      type: Boolean,
      // eslint-disable-next-line
      default: true,
    },
    onlyLeafNodeSelectable: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    hiddenOptionsKeys: {
      type: Array,
      default() {
        return []
      },
    },
    visibleOptionsKeys: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      nodes: [],
      selectedNodesId: [],
    }
  },
  watch: {
    data(newValue) {
      if (this.tree) {
        this.tree.loadData(newValue)
      }
    },
    searchTerm(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.filter()
        } else {
          this.tree.unfilter()
          window.dispatchEvent(new Event('resize'))
        }
      }
    },
    openIds(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (this.openIds.length) {
          this.openIds.forEach((id) => {
            const node = this.tree.getNodeById(id)
            if (node) {
              this.tree.openNode(node)
            }
          })
        }
      }
    },
    value(newValue, oldValue) {
      if (this.multiple && !IsEqual(newValue, oldValue)) {
        if (newValue.length) {
          newValue.forEach((v) => {
            this.openNodesAndSetSelected(v)
          })
        }
      }
    },
  },
  created() {
    this.filter = Debounce(this.filterRaw, 450)
    const options = { data: this.data, ...this.$attrs }

    options.rowRenderer = () => ''

    this.tree = new InfiniteTree(options)
    this.tree.nodes = this.removeDisabledNode()
    if (this.openIds.length) {
      this.openIds.forEach((id) => {
        const node = this.tree.getNodeById(id)
        if (node) {
          this.tree.openNode(node)
        }
      })
    }

    // Filters nodes.
    // https://github.com/cheton/infinite-tree/wiki/Functions:-Tree#filterpredicate-options
    const treeFilter = this.tree.filter.bind(this.tree)
    this.tree.filter = (...args) => {
      setTimeout(() => {
        const virtualList = this.$refs.virtualListRef
        if (virtualList) {
          virtualList.$forceUpdate()
        }
      }, 0)
      return treeFilter(...args)
    }

    // Unfilter nodes.
    // https://github.com/cheton/infinite-tree/wiki/Functions:-Tree#unfilter
    const treeUnfilter = this.tree.unfilter.bind(this.tree)
    this.tree.unfilter = (...args) => {
      setTimeout(() => {
        const virtualList = this.$refs.virtualListRef
        if (virtualList) {
          virtualList.$forceUpdate()
        }
      }, 0)
      return treeUnfilter(...args)
    }

    // Sets the current scroll position to this node.
    // @param {Node} node The Node object.
    // @return {boolean} Returns true on success, false otherwise.
    this.tree.scrollToNode = (node) => {
      const virtualList = this.$refs.virtualListRef

      if (!this.tree || !virtualList) {
        return false
      }

      const nodeIndex = this.tree.nodes.indexOf(node)
      if (nodeIndex < 0) {
        return false
      }

      virtualList.scrollToItem(nodeIndex)

      return true
    }

    // Gets (or sets) the current vertical position of the scroll bar.
    // @param {number} [value] If the value is specified, indicates the new position to set the scroll bar to.
    // @return {number} Returns the vertical scroll position.
    this.tree.scrollTop = (value) => {
      const virtualList = this.$refs.virtualListRef

      if (!this.tree || !virtualList) {
        return
      }

      if (value !== undefined) {
        virtualList.scrollToPosition(Number(value))
      }

      return virtualList.getScroll().start
    }

    // Updates the tree.
    this.tree.update = () => {
      this.tree.emit('contentWillUpdate')
      const filteredNodes = this.removeDisabledNode()
      if (this.searchTerm) {
        const nodes = filteredNodes.filter((node) => {
          return node.state.filtered !== false
        })
        this.nodes = UniqBy(nodes, 'id')
      } else {
        this.nodes = UniqBy(filteredNodes, 'id')
      }

      this.tree.emit('contentDidUpdate')
    }
    const rowHeight = this.rowHeight
    this.nodes = this.tree.nodes.map((node) => {
      node.size = rowHeight
      return node
    })

    this.tree.on('selectNode', (node) => {
      const virtualList = this.$refs.virtualListRef

      if (!this.tree || !virtualList) {
        return
      }
      virtualList.$forceUpdate()
    })
  },

  beforeDestroy() {
    this.tree.destroy()
    this.tree = null
  },

  methods: {
    removeDisabledNode() {
      return this.tree.nodes.filter((node) => {
        if (this.hiddenOptionsKeys.length) {
          return (
            node.disabled !== true &&
            this.hiddenOptionsKeys.indexOf(node.id) === -1
          )
        }
        if (this.visibleOptionsKeys.length) {
          return (
            node.disabled !== true &&
            this.visibleOptionsKeys.indexOf(node.id) >= 0
          )
        }
        return node.disabled !== true
      })
    },
    handleChange(item, tree) {
      if (item.disabled) {
        return
      }
      this.$emit('change', item, tree)
    },
    findFullPath(selectedNode) {
      let parents = []
      // eslint-disable-next-line
      function findParent(node) {
        if (!node.parent) {
          return
        }
        if (node.parent) {
          parents.push(node)
          findParent(node.parent)
        }
      }
      findParent(selectedNode)
      return parents
    },
    openNodesAndSetSelected(nodeId) {
      if (nodeId) {
        const selectedNode = this.tree.getNodeById(nodeId)
        if (!selectedNode.parent) {
          this.tree.openNode(selectedNode)
        } else {
          const parents = this.findFullPath(selectedNode)
          if (parents.length) {
            parents.forEach((node) => {
              this.tree.openNode(node)
            })
          }
        }
      }
    },
    selectInitialValue() {
      if (this.multiple) {
        if (this.value.length) {
          this.value.forEach((v) => {
            this.openNodesAndSetSelected(v)
          })
        }
      } else {
        if (this.value && this.value.id) {
          this.openNodesAndSetSelected(this.value.id)
        }
      }
    },
    async updateNode(id, data) {
      const nodes = Object.keys(this.tree.nodeTable.data)
        .filter((nodeId) => String(nodeId).indexOf(id) >= 0)
        .map((nodeId) => this.tree.nodeTable.data[nodeId])
      if (!nodes.length) {
        return
      }
      const node = nodes[0]
      if (node) {
        if (this.nodeFields.length) {
          this.nodeFields.forEach((field) => {
            node[field] = data[field]
          })
        }
        this.tree.nodeTable.data[node.id] = node
        this.tree.updateNode(node)
      }
    },
    async removeNode(id) {
      const nodes = Object.keys(this.tree.nodeTable.data)
        .filter((nodeId) => String(nodeId).indexOf(id) >= 0)
        .map((nodeId) => this.tree.nodeTable.data[nodeId])
      if (!nodes.length) {
        return
      }
      const node = nodes[0]
      if (node) {
        this.tree.removeNode(node)
      }
    },
    appendChildNode(chieldNode, parentNode) {
      this.tree.appendChildNode(chieldNode, parentNode)
      this.tree.openNode(parentNode)
    },
    updateTree(openNode) {
      this.tree.update()
      this.tree.openNode(openNode)
    },
    getRootNode() {
      return this.tree.getRootNode()
    },
    replaceNodeChildren(id, children) {
      const nodes = Object.keys(this.tree.nodeTable.data)
        .filter((nodeId) => String(nodeId).indexOf(id) >= 0)
        .map((nodeId) => this.tree.nodeTable.data[nodeId])
      if (!nodes.length) {
        return
      }
      const node = nodes[0]
      if (node) {
        this.tree.removeChildNodes(node)
        this.tree.addChildNodes(children, 0, node)
        this.tree.openNode(node)
      }
    },
    removeAllNodes() {
      const nodes = Object.keys(this.tree.nodeTable.data).map(
        (nodeId) => this.tree.nodeTable.data[nodeId]
      )
      if (!nodes.length) {
        return
      }
      nodes.forEach((node) => {
        this.tree.removeNode(node)
      })
    },
    addRootNodes(nodes) {
      this.tree.addChildNodes(nodes, 0)
    },
    filterRaw() {
      if (!this.tree) {
        return
      }
      const term = this.searchTerm

      this.tree.filter(
        this.filterFn ? (node) => this.filterFn(node, term) : term,
        {
          filterPath: 'name',
          caseSensitive: false,
          exactMatch: false,
          includeAncestors: true,
          includeDescendants: true,
        }
      )
      setTimeout(() => {
        this.nodes.forEach((n) => this.tree.openNode(n))
        window.dispatchEvent(new Event('resize'))
      })
    },
  },
}
</script>
