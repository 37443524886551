<template>
  <div :style="itemStyle">
    <slot
      v-if="!editing"
      name="item-renderer"
      :edit="edit"
      :remove="remove"
      :item="currentValue"
    >
      <div
        class="border border-solid border-neutral-lighter flex my-1 py-1 px-2 rounded"
      >
        <div class="flex-1">{{ currentValue }}</div>
        <div v-if="!disabled" class="action">
          <MTooltip>
            <template v-slot:trigger>
              <MIcon
                :id="`edit-${field}`"
                name="pencil"
                class="cursor-pointer mx-2 text-neutral-light"
                size="lg"
                @click="edit"
              />
            </template>
            {{ $t('edit') }}
          </MTooltip>
          <MTooltip v-if="canDelete">
            <template v-slot:trigger>
              <MIcon
                name="times-circle"
                class="cursor-pointer text-secondary-red"
                size="lg"
                @click="remove"
              />
            </template>
            {{ $t('delete') }}
          </MTooltip>
        </div>
      </div>
    </slot>
    <FlotoForm
      v-if="editing"
      layout="vertical"
      class="flex-grow flex"
      @submit="update"
    >
      <FlotoFormItem
        id="current-value-input"
        ref="optionTextField"
        v-model="currentValue"
        :size="inputSize"
        :rules="validationRules"
        :validation-label="field"
        class="flex-grow mb-0"
        style="padding-bottom: 0"
        :placeholder="field"
      />
      <template v-slot:submit="{ submit }">
        <div class="action-bar mt-2 flex">
          <a
            :title="$t('update')"
            href="javascript:;"
            class="font-semibold"
            @click="submit"
          >
            <slot name="submit-action">
              <MIcon name="check" class="text-secondary-green mx-2" size="lg" />
            </slot>
          </a>
          <a
            :title="$t('cancel')"
            class="mx-1 font-semibold"
            @click="cancelEdit"
          >
            <slot name="cancel-action">
              <MIcon name="times" class="text-secondary-red mx-1" size="lg" />
            </slot>
          </a>
        </div>
      </template>
    </FlotoForm>
  </div>
</template>

<script>
export default {
  name: 'InlineEdit',
  model: {
    event: 'change',
  },
  props: {
    value: { type: String, required: false, default: undefined },
    itemStyle: { type: Object, required: false, default: undefined },
    checkDuplicate: { type: Function, default: undefined },
    inputSize: { type: String, default: undefined },
    rules: { type: Object, default: undefined },
    addMode: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    // eslint-disable-next-line
    canDelete: { type: Boolean, default: true },
    field: {
      type: String,
      default() {
        return this.$tc('name')
      },
    },
    // eslint-disable-next-line
  },
  data() {
    return { editing: false, innerValue: '', active: false }
  },
  computed: {
    currentValue: {
      get() {
        return this.innerValue
      },
      set(value) {
        this.innerValue = value
      },
    },
    validationRules() {
      const r = {
        required: true,
        short_text: true,
      }
      if (this.rules) {
        return this.rules
      }
      return r
    },
  },
  watch: {
    value(newValue) {
      this.innerValue = newValue
    },
    editing(newValue, preValue) {
      if (newValue !== preValue) {
        this.$emit('current-editing', newValue)
      }
    },
  },
  created() {
    setTimeout(() => {
      this.$refs.optionTextField && this.$refs.optionTextField.focus()
    }, 100)
  },
  beforeMount() {
    this.innerValue = this.value
    if (this.addMode) {
      this.edit()
    }
  },
  methods: {
    edit() {
      this.editing = true
    },
    cancelEdit() {
      if (this.addMode) {
        this.$emit('cancel')
      } else {
        this.editing = false
        this.innerValue = this.value
      }
    },
    remove() {
      this.$emit('remove')
    },
    update() {
      if (
        this.checkDuplicate &&
        this.checkDuplicate(this.currentValue, this.value)
      ) {
        this.$refs.optionTextField.addError(
          this.$t('same_value_exists', { field: this.currentValue })
        )
      } else {
        this.$emit('change', this.currentValue)
        this.editing = false
      }
    },
  },
}
</script>
