var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FlotoCrudContainer',_vm._b({ref:"list",attrs:{"as-table":"","scrollable":_vm.searchable,"module-name":_vm.$constants.KNOWLEDGE,"fetch-fn":_vm.kbFetchFn,"columns":_vm.tableColumns,"selectable":_vm.selectable,"max-allowed-selection":_vm.maxAllowedSelection},on:{"selection-change":function($event){return _vm.$emit('selection-change', $event)}},scopedSlots:_vm._u([(_vm.searchable)?{key:"add-controls",fn:function(ref){
var refreshList = ref.refreshList;
return [_c('MRow',{staticClass:"items-center"},[_c('MCol',{staticClass:"flex items-center"},[_c('h2',{staticClass:"font-normal inline-block mb-0"},[_vm._v(" "+_vm._s(_vm.$tc('knowledge', 2))+" ")]),_c('div',{staticClass:"ml-2"},[_vm._t("after-title")],2)])],1),_c('MRow',{staticClass:"my-4"},[_c('MCol',[_c('FlotoSearchBar',{attrs:{"allow-save-search":false,"persist-criteria":false,"module-name":_vm.$constants.KNOWLEDGE},on:{"change":refreshList},model:{value:(_vm.searchCriteria),callback:function ($$v) {_vm.searchCriteria=$$v},expression:"searchCriteria"}})],1)],1)]}}:null,{key:"form",fn:function(){return [_c('span')]},proxy:true},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('ResourceListTitle',_vm._b({attrs:{"name":item.name,"subject":item.subject,"created-at":item.createdAt,"open-in-new-tab":"","link":_vm.$modules.getModuleRoute('knowledge', 'read', {
            params: { id: item.id },
          })}},'ResourceListTitle',_vm.$attrs,false))],1)]}},{key:"created_by",fn:function(ref){
          var item = ref.item;
return [_c('td',[_c('FlotoTechnicianPicker',{attrs:{"value":item.owner,"disabled":""}})],1)]}},{key:"status",fn:function(ref){
          var item = ref.item;
return [_c('td',[_c('FlotoDropdownPicker',{attrs:{"value":item.status,"disabled":"","options":_vm.statusOptions}})],1)]}},(_vm.hiddenColumns.indexOf('thumbs') < 0)?{key:"thumbs",fn:function(ref){
          var item = ref.item;
return [_c('td',[_c('FeedbackCount',{attrs:{"positive-feedback":item.positiveFeedback,"negative-feedback":item.negativeFeedback}})],1)]}}:null,{key:"actions",fn:function(ref){
          var item = ref.item;
return [_c('td',{class:{
        'sticky-action': _vm.actionSticked.indexOf(item.id) >= 0,
        'hover-action-container': _vm.hoverableActions,
      }},[_vm._t("action",null,{"item":item,"stickAction":function () { return _vm.stickActionFor(item); },"unstickAction":function () { return _vm.unStickActionFor(item); }})],2)]}},{key:"default",fn:function(slotData){return [_vm._t("default",null,null,slotData)]}}],null,true)},'FlotoCrudContainer',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }