<template>
  <Transition name="placeholder" appear>
    <DraggableElement v-if="showBubble">
      <div class="bubble-container text-white">
        <div class="flex flex-1 min-h-0">
          <div class="icon">
            <MIcon
              :name="setupGuideContext.currentStep.helpBubble.icon"
              size="2x"
            />
          </div>
          <div class="flex-1 min-w-0 flex flex-wrap flex-col min-h-0">
            <h3 class="mb-0 font-bold text-white">{{
              setupGuideContext.currentStep.helpBubble.title
            }}</h3>
            <div
              class="description w-full min-h-0 flex-1 overflow-y-auto"
              v-html="setupGuideContext.currentStep.helpBubble.description"
            >
            </div>
            <div class="mt-1 text-underline w-full">{{
              setupGuideContext.currentStep.route.text
            }}</div>
          </div>
        </div>
        <div class="mt-6 flex items-center justify-end">
          <div>
            <MButton @click="backToGuide">Back to Guide</MButton>
          </div>
        </div>
      </div>
    </DraggableElement>
  </Transition>
</template>

<script>
import Bus from '@utils/emitter'
import { availableSections as basicSections } from '@modules/product-setup/helper/basic-guide'
import { availableSections as slaSections } from '@modules/product-setup/helper/sla-guide'
import { availableSections as automationSections } from '@modules/product-setup/helper/automation-guide'
import DraggableElement from '@components/draggable-element.vue'
import { LicenseComputed } from '@/src/state/modules/license'

export default {
  name: 'SetupHelpBubbule',
  components: {
    DraggableElement,
  },
  inject: { setupGuideContext: { default: {} } },
  data() {
    return {
      showBubble: false,
    }
  },
  computed: {
    ...LicenseComputed,
    availableSections() {
      return {
        basic: basicSections(this.availableModulesInLicense),
        sla: slaSections(this.availableModulesInLicense),
        automation: automationSections(this.availableModulesInLicense),
      }
    },
  },
  watch: {
    $route(newValue, oldValue) {
      setTimeout(() => {
        this.checkRouteQuery()
      }, 400)
    },
  },
  mounted() {
    this.checkRouteQuery()
  },
  methods: {
    checkRouteQuery() {
      const setup = this.$route.query._setup
      if (setup) {
        try {
          const decodedSetup = JSON.parse(atob(decodeURIComponent(setup)))
          if (decodedSetup.__setupguide) {
            if (this.setupGuideContext.sections.length === 0) {
              if (
                decodedSetup.guide &&
                decodedSetup.section &&
                decodedSetup.step
              ) {
                this.setupGuideContext.init(
                  decodedSetup.guide,
                  this.availableSections[decodedSetup.guide] || []
                )
                this.setupGuideContext.goToSection(decodedSetup.section)
                this.setupGuideContext.goToStep(decodedSetup.step)
              }
            }
            setTimeout(() => {
              if (this.setupGuideContext.currentStep.helpBubble) {
                this.showBubble = true
              }
            })
          } else {
            this.showBubble = false
          }
        } catch (e) {
          this.showBubble = false
        }
      } else {
        this.showBubble = false
      }
    },
    hideFormDrawer() {
      Bus.$emit('setup:hide-form-drawer')
    },
    backToGuide() {
      this.showBubble = false
      this.setupGuideContext.moveToNextStep()
      this.hideFormDrawer()
      setTimeout(() => {
        if (
          this.setupGuideContext.isCurrentGuideCompleted &&
          this.$route.name === 'product-setup'
        ) {
          return
        }
        this.$router.push(
          this.$modules.getModuleRoute(
            'product-setup',
            this.setupGuideContext.isCurrentGuideCompleted
              ? undefined
              : this.setupGuideContext.guideType
          )
        )
      }, 400)
    },
  },
}
</script>

<style lang="less" scoped>
.bubble-container {
  position: fixed;
  top: calc(100% - 265px);
  left: 60px;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  max-width: 450px;
  height: 250px;
  user-select: none;
  background: #114e80;

  @apply rounded shadow-md p-4;

  .description {
    color: #7b8fa5;
  }

  .icon {
    background: rgb(255 255 255 / 10%);

    @apply rounded mr-2 p-2 mb-auto;
  }

  .text-underline {
    text-decoration: underline;
  }
}
</style>
