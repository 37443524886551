var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FlotoCrudContainer',{ref:"crudContainerRef",attrs:{"fetch-fn":_vm.getWorkLogs,"update-fn":_vm.updateWorkLog,"scrollable":false,"create-fn":_vm.addWorkLog,"delete-fn":_vm.removeWorkLog,"default-item":_vm.defaultWorklog,"columns":_vm.tableColumns,"show-no-data":false,"as-table":""},scopedSlots:_vm._u([{key:"add-controls",fn:function(ref){
var create = ref.create;
return [(!_vm.disabled)?_c(_vm.stickySearch ? 'MAffix' : 'div',{tag:"Component",attrs:{"offset-top":_vm.offsetTop},on:{"change":function($event){_vm.searchAffixed = $event}}},[_c('div',{staticClass:"sticky-tab-header",class:{ 'pt-2': _vm.searchAffixed }},[_c('MRow',[_c('MCol',{staticClass:"m-align-button"},[_vm._t("before-add-controls"),_c('MButton',{attrs:{"id":"add-worklog-btn","variant":"neutral"},on:{"click":create}},[_vm._v(" "+_vm._s(_vm.$t('add'))+" "+_vm._s(_vm.$tc('work_log'))+" ")])],2)],1),_c('MDivider')],1)]):_vm._e()]}},{key:"form-header",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.id ? 'edit' : 'add'))+" "+_vm._s(_vm.$tc('work_log'))+" ")]}},{key:"form-items",fn:function(ref){
var worklog = ref.item;
return [_c('WorkLogForm',{attrs:{"worklog":worklog,"group-id":_vm.groupId}})]}},{key:"form-actions",fn:function(ref){
var submit = ref.submit;
var cancel = ref.cancel;
var item = ref.item;
var processing = ref.processing;
return [_c('MButton',{staticClass:"mx-1",attrs:{"id":"add-update-btn","loading":processing},on:{"click":submit}},[_vm._v(" "+_vm._s(_vm.$t(item.id ? 'update' : 'add'))+" ")]),_c('MButton',{attrs:{"id":"cancel-btn","variant":"default"},on:{"click":cancel}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])]}},{key:"technicianId",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('FlotoTechnicianPicker',{staticClass:"cursor-default",attrs:{"value":item.technicianId,"disabled":"","placeholder":"---"}})],1)]}},{key:"startDate",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(_vm._s(_vm._f("datetime")(item.timeRange.startTime)))])]}},{key:"endDate",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(_vm._s(_vm._f("datetime")(item.timeRange.endTime)))])]}},{key:"timeTaken",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(_vm._s(_vm.calculateTimeTaken(item)))])]}},{key:"description",fn:function(ref){
var item = ref.item;
return [_c('MTooltip',{attrs:{"disabled":item.description.length <= 20},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(_vm._s(item.description))])]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"actions",fn:function(ref){
var item = ref.item;
var edit = ref.edit;
var remove = ref.remove;
return [_c('td',[_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('a',{staticClass:"text-neutral",on:{"click":edit}},[_c('MIcon',{attrs:{"name":"pencil"}})],1)]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('edit'))+" ")]),_c('FlotoDeleteBtn',{staticClass:"mx-2",attrs:{"message":_vm.$t('confirm_remove_item', { item: _vm.$t('work_log') })},on:{"confirm":remove}})],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }