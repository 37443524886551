<template>
  <FlotoCrudContainer
    ref="list"
    as-table
    :scrollable="searchable"
    :module-name="$constants.KNOWLEDGE"
    :fetch-fn="kbFetchFn"
    :columns="tableColumns"
    :selectable="selectable"
    :max-allowed-selection="maxAllowedSelection"
    v-bind="$attrs"
    @selection-change="$emit('selection-change', $event)"
  >
    <template v-if="searchable" v-slot:add-controls="{ refreshList }">
      <MRow class="items-center">
        <MCol class="flex items-center">
          <h2 class="font-normal inline-block mb-0">
            {{ $tc('knowledge', 2) }}
          </h2>
          <div class="ml-2">
            <slot name="after-title"> </slot>
          </div>
        </MCol>
      </MRow>
      <MRow class="my-4">
        <MCol>
          <FlotoSearchBar
            v-model="searchCriteria"
            :allow-save-search="false"
            :persist-criteria="false"
            :module-name="$constants.KNOWLEDGE"
            @change="refreshList"
          />
        </MCol>
      </MRow>
    </template>
    <template v-slot:form>
      <span />
    </template>
    <template v-slot:name="{ item }">
      <td>
        <ResourceListTitle
          :name="item.name"
          :subject="item.subject"
          :created-at="item.createdAt"
          open-in-new-tab
          :link="
            $modules.getModuleRoute('knowledge', 'read', {
              params: { id: item.id },
            })
          "
          v-bind="$attrs"
        />
      </td>
    </template>
    <template v-slot:created_by="{ item }">
      <td>
        <FlotoTechnicianPicker :value="item.owner" disabled />
      </td>
    </template>
    <template v-slot:status="{ item }">
      <td>
        <FlotoDropdownPicker
          :value="item.status"
          disabled
          :options="statusOptions"
        />
      </td>
    </template>
    <template
      v-if="hiddenColumns.indexOf('thumbs') < 0"
      v-slot:thumbs="{ item }"
    >
      <td>
        <FeedbackCount
          :positive-feedback="item.positiveFeedback"
          :negative-feedback="item.negativeFeedback"
        />
      </td>
    </template>
    <template v-slot:actions="{ item }">
      <td
        :class="{
          'sticky-action': actionSticked.indexOf(item.id) >= 0,
          'hover-action-container': hoverableActions,
        }"
      >
        <slot
          name="action"
          :item="item"
          :stick-action="() => stickActionFor(item)"
          :unstick-action="() => unStickActionFor(item)"
        />
      </td>
    </template>
    <template v-slot="slotData">
      <slot v-bind="slotData"></slot>
    </template>
  </FlotoCrudContainer>
</template>

<script>
import CloneDeep from 'lodash/cloneDeep'
import Uniq from 'lodash/uniq'
import {
  transformSearchCriteriasForServer,
  transformSearchCriteriaForServer,
} from '@data/search'
import { DraftPublishedStatusOptions } from '@utils/status'
import ResourceListTitle from '@components/resource/resource-list-title'
import { searchKnowledgeApi } from '@modules/knowledge/knowledge-api'
import FeedbackCount from '@modules/knowledge/components/feedback-count'
import { buildRelationalQualificationStructure } from '../../data/qualification'

export default {
  name: 'KBSelectionList',
  components: { ResourceListTitle, FeedbackCount },
  props: {
    fetchFn: { type: Function, default: undefined },
    hasActions: { type: Boolean, default: false },
    searchable: { type: Boolean, default: false },
    selectable: { type: Boolean, default: false },
    hiddenColumns: {
      type: Array,
      default() {
        return ['thumbs']
      },
    },
    excludeItemsById: {
      type: Array,
      default() {
        return []
      },
    },
    maxAllowedSelection: { type: Number, default: undefined },
    hoverableActions: { type: Boolean, default: false },
  },
  data() {
    this.statusOptions = DraftPublishedStatusOptions()
    this.tableColumns = [
      {
        name: this.$t('name'),
        key: 'name',
        minWidth: '200px',
      },
      {
        name: this.$t('author'),
        key: 'created_by',
      },
      {
        name: this.$t('status'),
        key: 'status',
        resizable: false,
        width: '100px',
      },
      ...(this.hiddenColumns.indexOf('thumbs') < 0
        ? [
            {
              name: '',
              key: 'thumbs',
              width: '150px',
              resizable: false,
            },
          ]
        : []),
      ...(this.hasActions
        ? [
            {
              name: `${this.$tc('action', 2)}`,
              key: 'actions',
              width: '80px',
            },
          ]
        : []),
    ]
    return {
      searchCriteria: transformSearchCriteriasForServer(
        this.$constants.KNOWLEDGE,
        []
      ),
      actionSticked: [],
    }
  },
  methods: {
    kbFetchFn(limit, offset) {
      const criterias = CloneDeep(this.searchCriteria)
      if (this.excludeItemsById.length) {
        const q = transformSearchCriteriaForServer(this.$constants.KNOWLEDGE, {
          operator: { value: 'not_in' },
          paramName: 'id',
          value: this.excludeItemsById,
          valueType: 'long',
        })
        criterias.quals.push(q)
      }
      criterias.quals.push(
        buildRelationalQualificationStructure(
          'knowledge.kbStatus',
          'equal',
          'published',
          'enum'
        )
      )
      if (this.fetchFn) {
        return this.fetchFn(undefined, criterias, limit, offset)
      } else {
        return searchKnowledgeApi(
          undefined,
          criterias.quals || [],
          limit,
          offset
        )
      }
    },
    refresh() {
      this.$refs.list.refresh()
    },
    stickActionFor(item) {
      this.actionSticked = Uniq([...this.actionSticked, item.id])
    },
    unStickActionFor(item) {
      this.actionSticked = this.actionSticked.filter((id) => id !== item.id)
    },
  },
}
</script>
