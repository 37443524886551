<template>
  <div class="flex flex-1 flex-col task-container">
    <FlotoCrudContainer
      ref="crudContainer"
      :scrollable="false"
      :fetch-fn="getTasks"
      :update-fn="updateTask"
      :create-fn="addTask"
      :delete-fn="deleteTask"
      :default-item="defaultTask"
      :show-no-data="showNoData"
      prepend-new-item
    >
      <template v-if="totalStages > 0" v-slot:add-controls>
        <MRow class="my-4 items-center justify-center px-6">
          <MCol auto-size>
            <b>{{ $tc('task') }} {{ $tc('summary') }}</b>
          </MCol>
          <MCol
            class="counter-steps"
            :style="{ maxWidth: `${totalStages * 150}px` }"
          >
            <MSteps
              :active="activeStage - 1"
              :status="activeStage === stages.length ? 'finish' : 'process'"
            >
              <MStep v-for="(stageInfo, index) in stages" :key="index">
                <template v-slot:title>
                  <CounterStep
                    :status="
                      index + 1 > activeStage
                        ? 'pending'
                        : index + 1 < activeStage
                        ? 'success'
                        : 'progress'
                    "
                    :active="currentActiveStage === index + 1"
                  >
                    <a class="block" @click="currentActiveStage = index + 1">
                      {{ stageInfo.completed }}/{{ stageInfo.total }}
                    </a>
                  </CounterStep>
                </template>
              </MStep>
            </MSteps>
          </MCol>
        </MRow>
        <MDivider />
      </template>
      <template v-if="!disabled" v-slot:form-header="{ item }">
        {{ $t(item.id ? 'edit' : 'add') }} {{ $tc('task') }}
      </template>
      <template v-slot:form-items="{ item: task }">
        <TaskForm :task="task" take-range take-status />
      </template>
      <template v-slot:form-actions="{ submit, cancel, item, processing }">
        <MButton id="add-update-btn" :loading="processing" @click="submit">
          {{ $t(item.id ? 'update' : 'add') }}
        </MButton>
        <MButton id="cancel-btn" variant="default" class="mx-1" @click="cancel">
          {{ $t('cancel') }}
        </MButton>
      </template>
      <template
        v-slot:item="{
          item: task,
          edit,
          update,
          activateItem,
          remove,
          isLastItem,
        }"
      >
        <Task
          :key="task.id"
          :task="task"
          mark-bg-color
          allow-edit
          allow-delete
          :disabled="disabled || activeStage < currentActiveStage"
          :is-in-active-task="activeStage < currentActiveStage"
          @view="activateItem(task)"
          @edit="edit"
          @remove="remove"
          @update="update"
        />
        <MDivider v-if="!isLastItem" />
      </template>
    </FlotoCrudContainer>
  </div>
</template>

<script>
import CloneDeep from 'lodash/cloneDeep'
import { defaultTask } from '@data/task'
import Bus from '@utils/emitter'
import CounterStep from '@components/counter-step'
import { createTaskApi, updateTaskApi, getTasksApi, deleteTaskApi } from './api'
import TaskForm from './task-form'
import Task from './task'

export default {
  name: 'TaskContainer',
  components: { Task, TaskForm, CounterStep },
  props: {
    resourceId: { type: Number, required: true },
    moduleName: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    hasStages: { type: Boolean, default: false },
    filters: { type: Object, required: true },
    showNoData: { type: Boolean, default: false },
    stages: {
      type: Array,
      default() {
        return []
      },
    },
    activeStage: { type: Number, default: undefined },
    totalStages: { type: Number, default: 0 },
  },
  data() {
    return {
      currentActiveStage: this.activeStage || 0,
      defaultTask: CloneDeep(defaultTask),
    }
  },
  watch: {
    activeStage(newValue) {
      this.currentActiveStage = newValue
    },
    currentActiveStage(newValue) {
      this.$refs.crudContainer.refresh()
    },
  },
  created() {
    const createTaskHandler = () => {
      this.$refs.crudContainer.showCreateForm()
    }
    Bus.$on('create-task', createTaskHandler)
    this.$once('hook:beforeDestroy', () => {
      Bus.$off('create-task', createTaskHandler)
    })
  },
  methods: {
    showCreateForm() {
      this.$refs.crudContainer.showCreateForm()
    },
    getTasks(limit, offset) {
      return getTasksApi(
        {
          moduleName: this.moduleName,
          filter: this.filters.keyword,
          stage: this.currentActiveStage,
          sortBy: this.filters.sortBy,
        },
        this.resourceId,
        limit,
        offset
      )
    },
    deleteTask(item) {
      return deleteTaskApi(this.moduleName, this.resourceId, item).then(() => {
        this.$emit('refresh-stages')
        this.$nextTick(() => this.$emit('refresh'))
      })
    },
    addTask(data) {
      return createTaskApi(this.moduleName, this.resourceId, {
        ...data,
      }).then((task) => {
        this.$nextTick(() => this.$emit('refresh'))
        return task
      })
    },
    updateTask(data) {
      return updateTaskApi(this.moduleName, this.resourceId, data).then(
        (task) => {
          if ('statusId' in data && this.hasStages) {
            this.$emit('refresh-stages')
          }
          this.$nextTick(() => this.$emit('refresh'))
          return task
        }
      )
    },
    refresh() {
      this.$refs.crudContainer.refresh()
    },
  },
}
</script>
