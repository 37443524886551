import api from '@api'
import { transformWorkLog, transformWorkLogForServer } from '@data/worklog'
import { getRootTranslator } from '@utils/get-module-translator'
const __t = getRootTranslator()

export function getWorklogApi(moduleName, id, pageSize, offset) {
  return api
    .get(`${moduleName}/${id}/worklog/`, {
      params: {
        offset: offset || 0,
        size: pageSize || 1000,
      },
      notify: false,
    })
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformWorkLog),
        total: data.totalCount,
      }
    })
}

export function getProjectWorklogApi(moduleName, id, pageSize, offset) {
  return api
    .post(
      `${moduleName}/${id}/task/worklog/`,
      {},
      {
        params: {
          offset: offset || 0,
          size: pageSize || 1000,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformWorkLog),
        total: data.totalCount,
      }
    })
}

export function createWorklogApi(moduleName, id, worklog) {
  return api
    .post(`${moduleName}/${id}/worklog`, transformWorkLogForServer(worklog), {
      message: __t('created_successfully', { resource: __t('work_log') }),
    })
    .then((data) => transformWorkLog(data))
}

export function updateWorklogApi(moduleName, refId, { id, ...worklog }) {
  return api
    .patch(
      `${moduleName}/${refId}/worklog/${id}`,
      transformWorkLogForServer(worklog),
      {
        message: __t('updated_successfully', { resource: __t('work_log') }),
      }
    )
    .then((data) => transformWorkLog(data))
}

export function deleteWorklogApi(moduleName, refId, { id }) {
  return api.delete(`${moduleName}/${refId}/worklog/${id}`, {
    message: __t('removed_successfully', { resource: __t('work_log') }),
  })
}
